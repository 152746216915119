import makeStyles from '@material-ui/core/styles/makeStyles';
import { SECONDARY } from '@core/theme/colors';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        borderRadius: 5,
        '&:disabled': {
            background: SECONDARY,
        },
    },
    fullWidth: {
        width: '100%',
        margin: 'auto',
        '&:disabled': {
            background: SECONDARY,
        },
    },
    loadRoot: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    centerAlign: {
        textAlign: 'center',
    },
    leftAlign: {
        textAlign: 'left',
    },
    rightAlign: {
        textAlign: 'right',
    },
    wrapper: {
        width: '100%',
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default useStyles;
